<template>
	<div v-if="webTraderProEnabled" class="banner">
		<div class="banner-blue" />
		<div class="banner-title">
			<h2 class="value">
				{{ $t('marketing.title') }}
			</h2>
			<p class="label">
				{{ $t('marketing.subtitle') }}
			</p>
		</div>
		<div class="banner-button-holder">
			<CButton tertiary full-width @click="navigateTo(webTraderProUrl)">
				{{ $t('marketing.button-text') }}
			</CButton>
		</div>
		<div class="banner-image">
			<img :src="require('../../assets/images/successfultraderguy.png')" />
		</div>
	</div>
</template>

<script>
import CButton from '@/shared/cbutton/CButton';

export default {
	name: 'MarketingCard',
	components: {
		CButton,
	},
	data() {
		return {
			webTraderProUrl: `${process.env.VUE_APP_PRO_WEBTRADER_URL}?request_login=true`,
			webTraderProEnabled: Boolean(process.env.VUE_APP_PRO_WEBTRADER_URL),
		};
	},
	methods: {
		navigateTo(link) {
			if (link.startsWith('/')) {
				this.$router.push({
					path: link,
				});
			} else {
				window.location.href = link;
			}
		},
	},
};
</script>

<style scoped lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.banner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 100px;
	overflow: hidden;
	position: relative;

	@include card-dark;

	@include max-screen(457px) {
		flex-direction: column;
		align-items: flex-start;
		gap: $spacer-s;
	}

	&-blue {
		position: absolute;
		background-color: $blue;
		width: 5px;
		height: 100%;
		left: 0px;

		@include max-screen($md - 1) {
			display: none;
		}
	}

	&-button-holder {
		width: 150px;
		@include max-screen($md) {
			width: 100%;
			margin-right: 30px;
		}

		.button {
			@include max-screen(457px) {
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				border-radius: 0px;
				overflow: none;
			}
		}
	}

	&-title {
		text-align: left;
		margin-left: 30px;
		width: 33%;
		padding-right: $spacer-l;

		@include max-screen(457px) {
			margin-top: $spacer-m;
		}

		@include max-screen($md) {
			width: 100%;
		}

		.label {
			margin: 0px;
			padding-right: 30px;
		}

		.value {
			margin: 0px;
			font-size: $body;
		}
	}

	&-image {
		height: 100px;
		width: 33%;

		@include max-screen($md) {
			display: none;
		}
	}
}
</style>
