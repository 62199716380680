<template>
	<button class="button" @click="onClick">
		<Spacer width size="s" />
		<img :src="require(`../../assets/icons/${icon}`)" :alt="iconAlt" class="image" />
		<Spacer width size="s" />
		<span class="dialog-span"> {{ title }}</span>
		<Spacer width size="s" />
	</button>
</template>

<script lang="ts">
import Spacer from '../spacer/Spacer.vue';

export default {
	name: 'ContextMenuItem',
	components: {
		Spacer,
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		icon: {
			type: String,
			default: null,
		},
		iconAlt: {
			type: String,
			default: null,
		},
		onClick: {
			type: Function,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
.button {
	display: flex;
	flex-direction: row;
	align-items: center;
	border: 0px;
	border-radius: 0px;
	background: #ffffff;
}

.dialog-span {
	font-weight: 400;
	font-size: $label-md;
	text-align: left;
	color: #505050;
}
</style>
