<template>
	<DashboardCard>
		<template #header>
			<dashboard-item-header :title="$t('dashboard.trading.title')" image-source="trading.svg" />
		</template>
		<template #footer>
			<div class="trading-buttons">
				<div class="button-wrapper">
					<span>{{ $t('dashboard.trading.starting-forex-journey') }}</span>
					<Spacer width size="s" />
					<CButton v-if="webtraderEnabled" class="button-flex green-button" secondary @click="navigateTo(webtraderUrl)">
						<span>{{ $t('dashboard.trading.trade-on') }}</span>
						<Spacer width size="s" />
						<div class="badge-success button-flex-badge">
							<span>NEO</span>
						</div>
					</CButton>
				</div>
				<Spacer width size="l" />
				<div class="line" />
				<Spacer width size="l" />
				<Spacer height size="s" />
				<div class="button-wrapper">
					<span>{{ $t('dashboard.trading.already-have-experience') }}</span>
					<Spacer width size="s" />
					<CButton
						v-if="webtraderProEnabled"
						class="button-flex blue-button"
						secondary
						@click="navigateTo(webtraderProUrl)"
					>
						<span>{{ $t('dashboard.trading.trade-on') }}</span>
						<Spacer width size="s" />
						<div class="badge-primary button-flex-badge">
							<span>PRO</span>
						</div>
					</CButton>
				</div>
			</div>
		</template>
	</DashboardCard>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardCard from '@/components/dashboard/DashboardCard';
import DashboardItemHeader from '@/shared/dashboard-item-header/DashboardItemHeader';
import CButton from '@/shared/cbutton/CButton';
import Spacer from '@/shared/spacer/Spacer.vue';

export default {
	name: 'TradingCard',
	components: {
		DashboardCard,
		DashboardItemHeader,
		CButton,
		Spacer,
	},
	data() {
		return {
			webtraderEnabled: Boolean(process.env.VUE_APP_WEBTRADER_URL),
			webtraderUrl: `${process.env.VUE_APP_WEBTRADER_URL}?request_login=true`,
			webtraderProUrl: `${process.env.VUE_APP_PRO_WEBTRADER_URL}?request_login=true`,
			webtraderProEnabled: Boolean(process.env.VUE_APP_PRO_WEBTRADER_URL),
		};
	},
	computed: {
		...mapGetters({
			user: 'getUser',
			username: 'getUsername',
		}),
	},
	methods: {
		navigateTo(link) {
			if (link.startsWith('/')) {
				this.$router.push({
					path: link,
				});
			} else {
				window.location.href = link;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.button-wrapper {
	display: flex;
	align-items: center;
	flex-grow: 1;
	flex-basis: 0;

	& > span {
		color: #ffffff;
		opacity: 0.5;
		font-size: $label-md;
		text-align: left;
		flex-grow: 1;
		flex-basis: 0;
	}

	& > button {
		flex-grow: 1;
		flex-basis: 0;
	}
}

.card {
	&-column {
		display: flex;
		flex-direction: column;
		text-align: left;

		.column-icon {
			display: flex;
			align-items: center;
		}
	}

	&-row {
		display: flex;
	}
}

.content-image {
	width: 14px;
	height: 14px;
}

.value {
	font-size: $title-md;
	color: $white;
	font-weight: $bold;
}

.label {
	font-size: $label-md;
	color: $white;
	opacity: 0.5;
}

.trading-content {
	display: grid;
	gap: $spacer-m;
	grid-template-columns: 1fr 1fr;
	text-align: left;

	@include max-screen($md) {
		grid-template-columns: 1fr;
	}
}

.trading-buttons {
	display: flex;

	@include max-screen($md) {
		flex-direction: column;
	}
}

.spacer-width-xl {
	width: $spacer-xl;
}

.green-button {
	border: 1px solid#70c157;
}

.blue-button {
	border: 1px solid #009ee3;
}

.line {
	width: 1px;
	background-color: #2a2e31;

	@include max-screen($md) {
		display: none;
	}
}

.button-flex {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 48px;

	&-badge {
		padding: 0px $spacer-xs 0px $spacer-xs;
		font-size: $label-sm;
	}
}
</style>
