<template>
	<PageLayout x-large-width>
		<div class="container">
			<Trading />
			<DashboardBalance />

			<div class="row">
				<PersonalDetails />
				<PartnerProgram />
			</div>
		</div>

		<Spacer height size="m" />
		<Marketing />
	</PageLayout>
</template>
<script>
import PageLayout from '@/components/page-layout/PageLayout';
import PersonalDetails from '@/components/dashboard/PersonalDetails';
import PartnerProgram from '@/components/dashboard/PartnerProgram';
import Trading from '@/components/dashboard/Trading';
import Marketing from '@/components/dashboard/Marketing';
import DashboardBalance from '@/shared/dashboard-balance/DashboardBalance.vue';
import Spacer from '@/shared/spacer/Spacer.vue';

export default {
	name: 'Dashboard',
	components: {
		PageLayout,
		DashboardBalance,
		PersonalDetails,
		Trading,
		PartnerProgram,
		Marketing,
		Spacer,
	},
};
</script>
<style scoped lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.container {
	display: flex;
	flex-direction: column;
	gap: $spacer-m;
}

.row {
	flex: 1;
	display: grid;
	gap: $spacer-m;
	grid-template-columns: 1fr 1fr;

	@include max-screen($md) {
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
	}
}
</style>
