<template>
	<div class="card">
		<slot name="header"></slot>
		<Spacer height size="l" />
		<div class="card-content">
			<slot name="content"></slot>
		</div>
		<Spacer height size="xl" />
		<slot name="footer"> </slot>
	</div>
</template>

<script>
import Spacer from '@/shared/spacer/Spacer.vue';

export default {
	name: 'DashboardCard',
	components: {
		Spacer,
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.card {
	@include card;
	flex-grow: 1;
	min-width: 100%;

	&-content {
		display: flex;
		align-items: flex-start;

		@include max-screen($md) {
			justify-content: space-between;
		}
	}
}
</style>
