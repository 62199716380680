<template>
	<div class="header-bar">
		<div class="start">
			<img :src="require(`../../assets/dashboard-icons/${imageSource}`)" :alt="imageLabel" class="image" />
			<h4 class="title">
				{{ title.toUpperCase() }}
			</h4>
		</div>
		<div ref="contextMenu" class="context">
			<slot v-if="contextMenuShow" name="contextMenu"> </slot>
		</div>
		<button v-if="contextMenuHasItems" class="dotted-button" @click="showContextMenu">
			<img class="icon__image" :src="require('../../assets/dashboard-icons/dots.svg')" />
		</button>
	</div>
</template>

<script lang="ts">
export default {
	name: 'DashboardItemHeader',
	props: {
		title: {
			type: String,
			default: null,
		},
		imageSource: {
			type: String,
			default: null,
		},
		imageLabel: {
			type: String,
			default: null,
		},
		onContextMenuOpen: {
			type: Function,
			default: null,
		},
	},
	data() {
		return {
			contextMenuShow: false,
		};
	},
	computed: {
		contextMenuHasItems() {
			return Boolean(this.$slots.contextMenu);
		},
	},
	watch: {
		contextMenuShow(val) {
			if (!val) {
				document.removeEventListener('click', this.closeContextMenu);
			} else {
				document.addEventListener('click', this.closeContextMenu, { capture: true });
			}
		},
	},
	methods: {
		showContextMenu() {
			this.contextMenuShow = true;
			if (this.onContextMenuOpen) {
				this.onContextMenuOpen();
			}
		},
		closeContextMenu(event) {
			if (this.$refs.contextMenu && !this.$refs.contextMenu.contains(event.target)) {
				this.contextMenuShow = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';

.header-bar {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	position: relative;
}

.dotted-button {
	background: none;
	border: none;
	padding: 0;
	margin: 0;
	align-self: center;
}

.dotted-button:focus {
	outline: none;
}

.start {
	display: flex;
	align-items: center;
	gap: $spacer-s;
}

.title {
	font-size: $title-md;
	font-weight: bold;
	margin: 0px;
	text-align: left;
}

.context {
	border-radius: 5px;
	overflow: hidden;
	background: #ffffff;
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
	position: absolute;
	right: 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
}

.dialog-span {
	font-weight: 400;
	font-size: 11px;
	text-align: left;
	color: #505050;
}
</style>
