<template>
	<div class="badge" :class="{ 'gray-border': isDemo, 'border-green': !isDemo }">
		<span>
      {{ $t(`wallets.${isDemo ? 'demo' : 'live'}`).toUpperCase() }}
    </span>
	</div>
</template>

<script>
export default {
	name: 'WalletTypeBadge',
	props: {
		isDemo: {
			type: Boolean,
			default: false,
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';

.badge {
	border-radius: 3px;
	padding: $spacer-xs $spacer-s;
	border: 2px solid green;
	font-size: $label-sm;
}

.green-border {
	border: 2px solid $mantis-green;
}

.gray-border {
	border: 2px solid $shark-black;
}
</style>
