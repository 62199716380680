<template>
	<DashboardCard>
		<template #header>
			<dashboard-item-header :title="$t('dashboard.personal-details.title')" image-source="personal-details.svg">
				<template #contextMenu>
					<context-menu-item
						:title="$t('dashboard.personal-details.change-details')"
						icon="change-info-small.svg"
						icon-alt="Change info"
						:on-click="navToPersonalDetails"
					/>
				</template>
			</dashboard-item-header>
		</template>
		<template #content>
			<div class="card-column">
				<span class="label">{{ $t('dashboard.personal-details.users-name') }}</span>
				<span class="value">{{ username }}</span>
			</div>
			<Spacer width size="xl" />
			<div class="test"></div>
			<div class="card-column">
				<span class="label">{{ $t('dashboard.personal-details.KYC-status') }}</span>
				<div class="column-icon">
					<div v-if="kycStatus.error !== null" class="error">
						<span>{{ $t(kycStatus.error) }}</span>
					</div>
					<div v-if="kycStatus.loading">
						<img class="rotate" :src="require('../../assets/icons/pending-2.svg')" />
					</div>
					<img
						v-if="!kycStatus.loading && !kycStatus.error"
						:class="getKycStatusProperties(kycStatus.status).class"
						:src="require(`../../assets/icons/${getKycStatusProperties(kycStatus.status).icon}`)"
					/>

					<Spacer width size="s" />
					<span v-if="kycStatus.error === null" class="value">{{ kycStatus.status || 'PENDING' }}</span>
				</div>
			</div>
		</template>
		<template #footer>
			<CButton
				v-if="kycStatus.status !== 'APPROVED' && !kycStatus.error && !kycStatus.loading"
				dark
				full-width
				@click="navigateTo('/documents/upload')"
			>
				{{ $t('dashboard.personal-details.upload-kyc-documents') }}
			</CButton>
		</template>
	</DashboardCard>
</template>

<script>
import apiClient from '../../api';
import { mapGetters } from 'vuex';
import DashboardCard from '@/components/dashboard/DashboardCard';
import DashboardItemHeader from '@/shared/dashboard-item-header/DashboardItemHeader';
import CButton from '@/shared/cbutton/CButton';
import ContextMenuItem from '@/shared/context-menu-item/ContextMenuItem';
import Spacer from '@/shared/spacer/Spacer.vue';

export default {
	name: 'PersonalDetails',
	components: {
		DashboardCard,
		DashboardItemHeader,
		CButton,
		ContextMenuItem,
		Spacer,
	},
	data() {
		return {
			kycStatus: {
				status: null,
				loading: false,
				error: null,
			},
		};
	},
	computed: {
		...mapGetters({
			user: 'getUser',
			username: 'getUsername',
		}),
	},
	async mounted() {
		await this.fetchKycStatus();
	},
	methods: {
		navToPersonalDetails() {
			this.navigateTo('/profile');
		},
		navigateTo(link) {
			if (link.startsWith('/')) {
				this.$router.push({
					path: link,
				});
			} else {
				window.location.href = link;
			}
		},
		async fetchKycStatus() {
			try {
				this.kycStatus.loading = true;
				const data = await apiClient.fetchKycStatus();
				this.kycStatus.status = data.status;
			} catch (e) {
				this.kycStatus.error = 'dashboard.kyc-status.failed-to-load';
			} finally {
				this.kycStatus.loading = false;
			}
		},
		getKycStatusProperties(status) {
			const properties = {
				APPROVED: {
					class: 'content-image',
					icon: 'success.png',
				},
				REVOKED: {
					class: 'content-image',
					icon: 'error.png',
				},
				PENDING: {
					class: 'rotate',
					icon: 'pending-2.svg',
				},
			};

			if (status === null) {
				return properties['PENDING'];
			}

			return properties[status];
		},
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.card {
	&-column {
		display: flex;
		flex-direction: column;
		text-align: left;

		.column-icon {
			display: flex;
			align-items: center;
		}
	}

	&-row {
		display: flex;
	}
}

.content-image {
	width: 14px;
	height: 14px;
}

.value {
	font-size: $title-md;
	color: $white;
	font-weight: $bold;
}

.label {
	font-size: $label-md;
	color: $white;
	opacity: 0.5;
}
</style>
